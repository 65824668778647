<template>
  <div>
    <v-container fluid>
      <v-row justify="center">
        <v-col sm="12" md="10" lg="10">
          <v-card flat>
            <v-toolbar flat></v-toolbar>
          </v-card>
          <v-card outlined tile>
            <v-card-title>
              프로필
            </v-card-title>
            <v-divider/>
            <v-row class="my-2">
              <v-col cols="12" sm="12" class="align-self-center">
                <v-row align="center" justify="center" class="mr-0">
                  <!-- <v-hover>
                    <template v-slot:default="{ hover }">
                      <v-progress-circular
                        v-if="uploading"
                        :size="100"
                        :width="15"
                        :rotate="-90"
                        :value="progressUpload"
                        color="primary">
                        {{ progressUpload }}%
                      </v-progress-circular>

                      <v-avatar size="96" class="my-3" v-else>
                        <v-img
                          v-if="imageUrl" :src="imageUrl"
                        ></v-img>
                        <v-img
                          v-else
                        ><v-icon size="96" color="grey lighten-1">mdi-account-circle</v-icon></v-img>

                        <v-fade-transition>
                          <v-overlay
                            v-if="hover"
                            absolute
                            color="#036358"
                          >
                            <v-btn  type="button" @click="onClickImageUpload"><v-icon>mdi-camera</v-icon></v-btn>
                          </v-overlay>
                        </v-fade-transition>
                      </v-avatar>
                    </template>
                  </v-hover> -->
                      <v-avatar size="96" class="my-3">
                        <v-img
                          v-if="imageUrl" :src="imageUrl"
                        ></v-img>
                        <v-img
                          v-else
                        ><v-icon size="96" color="grey lighten-1">mdi-account-circle</v-icon></v-img>
                      </v-avatar>
                </v-row>
                <image-uploader
                  hidden
                  ref="imageInput"
                  :preview="true"
                  :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                  capture="environment"
                  :debug="0"
                  :autoRotate="true"
                  outputFormat="verbose"
                  @input="setImage"
                  :maxWidth="200"
                  :quality="0.7"
                  doNotResize="['gif', 'svg']"
                ></image-uploader>
              </v-col>
              <v-col cols="12" sm="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-subtitle class="caption">아이디</v-list-item-subtitle>
                    <v-list-item-title>{{ user.userid }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-subtitle class="caption">이름</v-list-item-subtitle>
                    <v-list-item-title>{{ user.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-subtitle class="caption">학년</v-list-item-subtitle>
                    <v-list-item-title>{{ user.grade }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <!-- <v-col cols="12" sm="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-subtitle class="caption">스티커</v-list-item-subtitle>
                    <v-list-item-title>
                      <v-menu offset-y max-width="324">
                        <template v-slot:activator="{ on }">
                          <v-img>
                            <v-icon color="pink">{{ user.stickerIcon }}</v-icon>
                            <v-btn v-on="on" text small><span class="subtitle-1 grey--text">스티커 변경</span></v-btn>
                          </v-img>
                        </template>
                        <v-card
                          class="d-flex flex-wrap" max-width="324"
                        >
                          <v-card
                            v-for="(item, i) in emojis"
                            :key="i"
                            flat
                            class="pa-3"
                          >
                            <v-icon
                              size="30"
                              color="pink"
                              @click="clickEmoji(item.name)"
                            >{{ item.name }}</v-icon>
                          </v-card>
                        </v-card>
                      </v-menu>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-subtitle class="caption">스티커</v-list-item-subtitle>
                    <v-list-item-title>
                      <v-icon
                        dense
                        color="red darken-2"
                        dark
                      >mdi-stamper</v-icon>
                      {{ user.stickerCnt }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-subtitle class="caption">포인트</v-list-item-subtitle>
                    <v-list-item-title>
                      <v-icon
                        dense
                        color="teal darken-2"
                        dark
                      >mdi-file-powerpoint-box</v-icon>
                      {{ numberWithComma }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col> -->
            </v-row>
          </v-card>

        </v-col>
      </v-row>
    </v-container>
  </div>

</template>
<script>
import ImageUploader from 'vue-image-upload-resize'

export default {
  components: { ImageUploader },
  data () {
    return {
      imageUrl: '',
      progressUpload: 0,
      uploadTask: '',
      firebaseImageUrl: '',
      firebaseImageName: '',
      uploading: false,
      uploaded: false,
      hasImage: false,
      form: {},
      emojis: [],
      user: {}
    }
  },
  computed: {
    // user () {
    //   let user = this.$store.state.stdUser.user
    //   if (!user) user = localStorage.getItem('stduser')
    //   return user
    // },
    numberWithComma () {
      if (!this.user.pointScore) return '0'
      const x = this.user.pointScore
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  watch: {
    uploadTask: function () {
      this.uploadTask.on('state_changed', sp => {
        this.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
      },
      null,
      () => {
        this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          this.firebaseImageUrl = downloadURL
          this.uploading = false
          this.uploaded = true
          // this.$emit('downloadURL', downloadURL)
          this.updatePhoto()
        })
      })
    }
  },
  created () {
    this.read()
    this.readEmoji()
  },
  methods: {
    async read () {
      await this.$axios.get('manage/user')
        .then((r) => {
          this.user = r.data.user
          // console.log(this.user)
        })
        .catch((e) => {
          this.$store.commit('pop', { msg: '연결이 끊겼습니다. 다시 로그인 해주세요', color: 'warning' })
        })

      if (this.user.photoURL) {
        this.imageUrl = this.user.photoURL
      }
    },
    async readEmoji () {
      this.emojis = []
      await this.$axios.get('manage/emojis')
        .then((r) => {
          this.emojis = r.data.emojis
          // console.log(r.data.emojis)
        })
        .catch((e) => {
          this.$store.commit('pop', { msg: '연결이 끊겼습니다. 다시 로그인 해주세요', color: 'warning' })
        })
      // this.emojis = []
      // this.$firebase.firestore().collection('infos').doc('emojis').get()
      //   .then((r) => {
      //     if (r.exists) {
      //       for (let i = 0; i < r.data().data.length; i++) {
      //         this.emojis.push(r.data().data[i])
      //       }
      //     }
      //   })
    },
    clickEmoji (name) {
      // this.user.stickerIcon = name

      this.$axios.put(`manage/user/${this.user.userdoc}`, { name: name })
        .then((r) => {
          this.user.stickerIcon = name
        })
        .catch((e) => {
          this.$store.commit('pop', { msg: '연결이 끊겼습니다. 다시 로그인 해주세요', color: 'warning' })
        })

      // this.$axios.put(`manage/board/${board._id}`, this.form)
      // .then((r) => {
      //   if (!r.data.success) throw new Error(r.data.msg)
      //   board.name = this.form.name
      //   board.title = this.form.title
      //   board.rmk = this.form.rmk
      //   board.lv = this.form.lv
      //   this.edit = false
      // })
      // .catch((e) => {
      //   if (!e.response) this.$store.commit('pop', { msg: e.message, color: 'warning' })
      // })
    },
    onClickImageUpload () {
      this.$refs.imageInput.$el.children[1].click()
    },
    setImage (f) {
      this.hasImage = true
      this.imageUrl = f.dataUrl

      this.upload(f)
    },
    upload (file) {
      const fileName = new Date().getTime()
      const ext = '.' + file.info.name.split('.')[1]
      try {
        const storageRef = this.$firebase.storage().ref()

        this.uploadTask = storageRef.child('profiles/' + this.$store.state.user.uid + '/' + fileName + ext).putString(file.dataUrl, 'data_url')
        this.firebaseImageName = fileName + ext
        this.uploading = true
      } catch (e) {
        console.log(e.message)
      }
    },
    updatePhoto () {
      try {
        const user = this.$firebase.auth().currentUser

        user.updateProfile({
          photoURL: this.firebaseImageUrl
        }).then(r => {
          this.$store.dispatch('getUser', user)
        })

        this.$firebase.firestore().collection('users').doc(this.$store.state.user.uid).update({
          photoName: this.firebaseImageName
        }).then(() => {
          if (this.form.photoName) {
            this.delStorageFile()
          }
        })
      } catch (e) {
        console.log(e.message)
      }
    },
    async delStorageFile () {
      const fileName = 'profiles/' + this.$store.state.user.uid + '/' + this.form.photoName
      const storageRef = await this.$firebase.storage().ref().child(fileName)
      storageRef.delete().then(() => {
        // console.log('delStorageFile', fileName)
      }).catch(e => {
        console.log(e.message)
      })
    }
  }
}
</script>
